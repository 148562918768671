// Font family
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

// Additional less libraries
@import "./preflight.less";

html,
body {
  font-family: "Quicksand", sans-serif;
  background-color: #fffdf4;
  height: auto;

  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Quicksand", sans-serif !important;
}

a {
  text-decoration: none;
}

// Override notification styles
.ant-notification-notice {
  border-radius: 8px;
  border: solid 1px #e4e4e7; // grey-200

  & .ant-notification-notice-message {
    font-weight: 500; // medium
  }

  & .ant-notification-notice-description {
    color: #71717a; // grey-500
  }
}

// Override popover styles
.ant-popover {
  & > .ant-popover-content {
    & > .ant-popover-arrow {
      display: none;
    }

    & > .ant-popover-inner {
      border-radius: 8px;
      border: solid 1px #e4e4e7; // grey-200
    }
  }
}

// Override table styles
.ant-table-thead > tr > th {
  &::before {
    display: none;
  }

  background: transparent;
}

// Override pagination
.ant-pagination {
  .ant-pagination-total-text {
    flex: 1;
  }

  .ant-pagination-options {
    order: -1;
    margin-right: 8px;

    & .ant-select-selector {
      border-color: transparent;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-item {
    margin-right: 0;
    border-collapse: collapse;
  }

  .ant-pagination-prev {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    button {
      border-radius: inherit;
    }
  }

  .ant-pagination-next {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    button {
      border-radius: inherit;
    }
  }
}

div.rule-forum {
  color: #4b5563 !important;
}

.otp-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: auto;
  justify-content: center;
}

.otp-input {
  width: 58px !important;
  height: 58px;
  border-radius: 8px;
  background-color: #f3f4f6;
  border: solid 2px #f3f4f6;
  font-size: 24px;
}

.slider-7d {
  margin-top: 20px;
  margin-bottom: 80px;

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;

    &.slick-disabled {
      &:before {
        opacity: 0;
      }
    }

    &:before {
      font-size: 40px;
      color: rgba(113, 113, 113, 0.5);
    }
  }

  .slick-list {
    .slick-track {
      .slick-slide {
        width: 173px !important;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.photo-grid-2,
.photo-grid-3 {
  & > div {
    &:first-child > img {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child > img {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.photo-grid-4 {
  & > div {
    &:first-child > img {
      border-top-left-radius: 8px;
    }

    &:nth-child(2) > img {
      border-top-right-radius: 8px;
    }

    &:nth-child(3) > img {
      border-bottom-left-radius: 8px;
    }

    &:last-child > img {
      border-bottom-right-radius: 8px;
    }
  }
}

.photo-grid-5a {
  & > div {
    &:first-child > img {
      border-top-left-radius: 8px;
    }

    &:last-child > img {
      border-top-right-radius: 8px;
    }
  }
}

.photo-grid-5b {
  & > div {
    &:first-child > img {
      border-bottom-left-radius: 8px;
    }

    &:last-child > img {
      border-bottom-right-radius: 8px;
    }
  }
}

.photo-grid-6 {
  & > div {
    &:first-child > img {
      border-top-left-radius: 8px;
    }

    &:nth-child(3) > img {
      border-top-right-radius: 8px;
    }

    &:nth-child(4) > img {
      border-bottom-left-radius: 8px;
    }

    &:last-child > img {
      border-bottom-right-radius: 8px;
    }
  }
}

// Story
.story-container {
  overflow: hidden;
  & > img {
    width: 100%;
    height: auto;
    object-fit: unset;
  }

  & > div[class^="Story-styles_header"] {
    z-index: 99;
  }

  & > div[class^="Video-styles_soundIcon"] {
    z-index: 101;
  }
}

.ant-message {
  z-index: 1090 !important;
}

.important-message {
  z-index: 1090 !important;
}

@page {
  size: A4;
  margin: 2cm 0cm;
  page-break-after: always;

  @bottom-right {
    content: counter(page) " of " counter(pages);
    color: #71717a;
  }

  @top-right {
    content: "Printed on " counter(date);
    color: #71717a;
  }
}

@page :first {
  margin: 0;
}

.printable-page {
  background-color: #fff;
  width: 210mm;
  margin: 0 auto;
  // min-width: 100%;
  min-height: 100vh;

  @media print {
    width: 210mm;
    height: 297mm;
    margin: 0;
  }
}
